<template>
  <div v-title data-title="Home">
    <div
      v-if="offsetwidth > 850"
      style="
        width: 100%;
        overflow: hidden;
        position: relative;
        min-width: 1349px;margin-top: 65px
      "
    >
      <div style="width: 100%">
        <el-carousel
          :height="imgHeight"
          trigger="click"
          :interval="6000"
          arrow="always"
        >
          <el-carousel-item v-for="(item, index) in images" :key="index">
            <img
              ref="imgHeight"
              :src="item.idview"
              alt="Nodata"
              style="width: 100%"
              @load="imgLoad"
            />
            <div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
         <div style="position: relative">
        <img
          style="width: 100%"
         src="../assets/imgthree/PCNEW/homewelcome.jpg"
          alt=""
        />

        <img
          style="
           width:13%;
            position: absolute;
            top: 76%;
            left: 56%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="gotoAboutus()"
          src="../assets/imgthree/PCNEW/homebutton.png"
          alt=""
        />
      </div>

      <img
        style="width: 100%"
        src="../assets/imgthree/PCNEW/homeaboutus.png"
        alt=""
      />
       <img
        style="width: 100%"
        src="../assets/imgthree/PCNEW/homefeatures.png"
        alt=""
      />
      
       <img
        style="width: 100%"
        src="../assets/imgthree/PCNEW/homebottom.jpg"
        alt=""
      />
    </div>
    <div
      v-else
      style="
        width: 100%;
         border: 1px solid #f8f9fa;
        overflow: hidden;
        position: relative;margin-top: 65px
      "
    >
      <div style="width: 100%">
        <el-carousel
          :height="imgHeight"
          trigger="click"
          :interval="6000"
          arrow="always"
        >
          <el-carousel-item v-for="(item, index) in imagesh5" :key="index">
            <img
              ref="imgHeight"
              :src="item.idview"
              alt="Nodata"
              style="width: 100%"
              @load="imgLoad"
            />
            <div>
     
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
         <div style="position: relative">
        <img
          style="width: 100%"
        src="../assets/imgthree/H5NEW/按钮背景.jpg"
          alt=""
        />

        <img
          style="
           width:33%;
            position: absolute;
            top: 86%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="gotoAboutus()"
          src="../assets/imgthree/H5NEW/按钮H5.png"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/imgthree/H5NEW/homeaboutus.png" alt="" />
      <img style="width: 100%" src="../assets/imgthree/H5NEW/底部H5.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
   props: {
    offsetwidth: {
      type: Number,
    },
  },
  data() {
    return {
      imagesh5: [
        {
          idview: require("../assets/imgthree/H5NEW/轮播图1H5.jpg"),
        },
        {  idview: require("../assets/imgthree/H5NEW/轮播图2H5.jpg") },
      ],
      imgHeight: "",
      // 产品图片，注意图片命名
      images: [
        {
          idview: require("../assets/imgthree/PCNEW/轮播图1.jpg"),
        },
        { idview: require("../assets/imgthree/PCNEW/轮播图2.jpg") },
        // { name: "home", idview: require("../assets/imgthree/PC/hometop3.png") },
      ],
    };
  },
  mounted() {
    this.imgLoad();
    window.onresize = () => {
      this.imgLoad();
    };

    // window.fbq("track", "ViewContent", {
    //   content_type: "index",
    //   content_ids: ["10001"],
    //   content_name: "index",
    //   content_category: "index",
    // });
  },
  destroyed() {
    window.onresize = null;
  },
  imgLoad() {
    this.$nextTick(() => {
      this.imgHeight = this.$refs.imgHeight["0"].height + "px";
    });
  },

  methods: {
    gotoAboutus(){
      this.$emit('fun', '/AboutUs');
   
    },
    imgLoad() {
      this.$nextTick(() => {
        this.imgHeight = this.$refs.imgHeight["0"].height + "px";
      });
    },


  
  },
};
</script>

<style  scoped lang="scss">
//  .el-carousel__item h3 {
//     color: #475669;
//     font-size: 18px;
//     opacity: 0.75;
//     line-height: 300px;
//     margin: 0;
//   }

//   .el-carousel__item:nth-child(2n) {
//     background-color: #99a9bf;
//   }

//   .el-carousel__item:nth-child(2n+1) {
//     background-color: #d3dce6;
//   }
::v-deep .el-carousel__arrow--left {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 56px;
  width: 56px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  background-color: #c0c4cc;
  color: #fff;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  font-size: 30px;
  // top: 70px;
  // left: -13px;
  // font-size: 15px;
  // color: #02E9FF;
  // display: none;
}
// ::v-deep.el-carousel__arrow {
//     border: none;
//     outline: 0;
//     padding: 0;
//     margin: 0;
//     height: 56px;
//     width: 56px;
//     cursor: pointer;
//     transition: .3s;
//     border-radius: 50%;
//     background-color: rgba(255,255,255,.5);
//     color: #FFF;
//     position: absolute;
//     top: 50%;
//     z-index: 10;
//     transform: translateY(-50%);
//     text-align: center;
//     font-size: 30px;
// }
//右箭头
::v-deep .el-carousel__arrow--right {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 56px;
  width: 56px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  background-color: #c0c4cc;
  color: #fff;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  font-size: 30px;
  // display: none;
  // top: 70px;
  // right: -13px;
  // font-size: 15px;
  // color: #02E9FF;
}
// .el-carousel__item h3 {
//     color: #475669;
//     font-size: 14px;
//     opacity: 0.75;
//     line-height: 150px;
//     margin: 0;
//   }

//   .el-carousel__item:nth-child(2n) {
//     background-color: #99a9bf;
//   }

//   .el-carousel__item:nth-child(2n + 1) {
//     background-color: #d3dce6;
//   }
// ::v-deep .el-carousel__indicator--horizontal {
//        position: relative;
//        bottom:0;
//         transform: translateX(-50%);
//         // left: auto;
//         // right: 10px;
//         // bottom: 10px;
//         // text-align: right;

// }
// ::v-deep.el-carousel__indicators { // 指示灯位置
//   left: unset;
//   transform: unset;
//   right: 2%;
// }
::v-deep .el-carousel__button {
  left: auto;
  right: 10px;
  bottom: 10px;
  text-align: right;
  // top: 4.5%;
  // right: 22%;
  display: block;
  opacity: 0.48;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: none;
  outline: 0;
  padding: 0;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}
// .el-carousel__item h3 {
//   color: #475669;
//   font-size: 14px;
//   opacity: 0.75;
//   line-height: 150px;
//   margin: 0;
// }

// .el-carousel__item:nth-child(2n) {
//    background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n+1) {
//    background-color: #d3dce6;
// }
::v-deep .pchome.el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 15px;
}
// .el-carousel__item.is-animation{
//   transition:transform 1s ease-in-out;
// }
::v-deep .h5home .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-weight: 700;
  font-size: 17px;
}
.el-form-item {
  margin-bottom: 15px;
}
::v-deep.el-select .el-input__inner {
  border-radius: 20px;
}
.mt_20 {
  margin-top: 20px;
}
.mb_20 {
  margin-bottom: 20px;
}
::v-deep.el-input--suffix .el-input__inner {
  padding-right: 30px;
  border-radius: 20px;
}
</style>
