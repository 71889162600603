import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [{
  path: "/",
  name: "Home",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/index.vue"),
},
{
  path: "/CustomerDetails",
  name: "CustomerDetails",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/home.vue"),
},
{
  path: "/MAC",
  name: "MAC",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/MAC.vue"),
},
{
  path: "/HomeEssential",
  name: "HomeEssential",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/HomeEssential.vue"),
},
{
  path: "/SolarPanelSystem",
  name: "SolarPanelSystem",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/SolarPanelSystem.vue"),
},
{
  path: "/Home",
  name: "Home",
  component: () =>
    import( /* webpackChunkName: "about" */ "../views/Home.vue"),
},
{
  path: "/index-2",
  name: "index-2",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/index.vue"),
},
{
  path: "/index-1",
  name: "index-1",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/index.vue"),
},
{
  path: "/TC",
  name: "Terms and Conditions",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/TC.vue"),
},
{
  path: "/AboutUs",
  name: "AboutUs",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/AboutUs.vue"),
},

{
  path: "/OurProducts",
  name: "Our Products",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/Products.vue"),
},
{
  path: "/ContactUs",
  name: "ContactUs",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/ContactUs.vue"),
},
{
  path: "/index",
  name: "Index",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/index.vue"),
},
{
  path: "/gallery",
  name: "gallery",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/gallery.vue"),
},
{
  path: "/privacyPolicy",
  name: "Privacy Polocy",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/privacyPolicy.vue"),
},
{
  path: "/privacypolicycopy",
  name: "Privacy Polocy",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/privacypolicy copy.vue"),
},
{
  path: "/CustomerSolarDetails",
  name: "CustomerSolarDetails",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/home1.vue"),
},
{
  path: "/ConfirmationSolar",
  name: "ConfirmationSolar",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/Finish1.vue"),
},
{
  path: "/CustomerHPDetails",
  name: "CustomerHPDetails",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/home2.vue"),
},
{
  path: "/ConfirmationHP",
  name: "ConfirmationHP",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/Finish2.vue"),
},
{
  path: "/Confirmation",
  name: "Confirmation",
  component: () =>
    import( /* webpackChunkName: "about" */ "../components/Finish.vue"),
},
];
const router = new VueRouter({
  mode: "history",
  routes,
});
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
// router.beforeEach((to, from, next) => {
//   let token = sessionStorage.getItem('token');

//   //debugger
//   if (to.path === '/login'||to.path === '/registe') {
//     next();
//   } else {
//     // let token = sessionStorage.getItem('token');
//     if (!token) {
//       next('/login');
//     } else {
//       next();
//     }
//   }
// });
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
// if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
// return originalPush.call(this, location).catch(err => err)
// }

export default router;