<template>
  <div class="home">
    <el-container>
      <el-header
        v-if="offsetwidth > 850"
        style="background-color: white;height:65px;align-items: center; width: 100%;display:flex;justify-content:center"
      >
        <el-menu
          router
          :default-active="activeIndex"
          background-color="white"
          text-color="black"
          active-text-color="rgb(34, 172, 56)"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          style="margin-left: 360px"
        >
          <div style="float: left">
            <img style="height: 50px" src="../assets/logo.png" alt="" />
          </div>
          <el-menu-item
            style="margin-left: 3%"
            index="/index"
            >Home</el-menu-item
          >
          <el-menu-item
            style="margin-left: 3%"
            index="/AboutUs"
            >About Us</el-menu-item
          >
           <el-menu-item
            style="margin-left: 3%"
            index="/OurProducts"
            >Our Products</el-menu-item
          >
             <el-menu-item style="margin-left: 3%" index="/ContactUs"
            >Contact Us</el-menu-item
          >
        </el-menu>
        
      </el-header>
 
      <el-header v-else style="background-color: white;height:65px; ">
        <el-menu
          router
          :default-active="activeIndex"
          background-color="white"
          text-color="black"
          active-text-color="rgb(34, 172, 56)"
          class="el-menu-demo"
          @select="handleSelect"
        >
          <div style="display:flex;justify-content: flex-start;">
            <img style="height: 50px" src="../assets/logo.png" alt="" />
          </div>
          <div
            @click="showmenu = !showmenu"
            style="background-color: #fff; width: 50px;padding:5px;0;border-radius:5px;position:absolute;top:10px;right:10px;"
          >
            <img src="../assets/imgTwo/H5/menu.png" style="width: 40px" />
          </div>
           <el-menu-item
           v-show="showmenu" 
            index="/index"
            >Home</el-menu-item
          >
          <el-menu-item
            index="/AboutUs"
             v-show="showmenu" 
             style="text-align:left"
            >About Us</el-menu-item
          >
           <el-menu-item
            index="/OurProducts"
             v-show="showmenu" 
             style="text-align:left"
            >Our Products</el-menu-item
          >
            <el-menu-item
            index="/ContactUs"
             v-show="showmenu" 
             style="text-align:left"
            >Contact Us</el-menu-item
          >
          
        </el-menu>
      </el-header> 
     
      <div style="width: 100%" class="flex_around">
        <router-view
          style="width: 100%"
          @fun="getinfo"
          :offsetwidth="offsetwidth"
        ></router-view>
      </div>
      <!-- </el-main> -->
      <!-- <el-footer>Footer</el-footer> -->
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  data() {
    return {
       key: "",
      showmenu: false,
      activeIndex: "/index",
      offsetwidth: document.documentElement.clientWidth,
    };
  },
  // computed: {
  //   activeIndex() {
  //     return window.sessionStorage.getItem("activeIndex");
  //   },
  // },
  watch: {
    // 如果 `offset ` 发生改变，这个函数就会运行
    offsetwidth: function (val) {
      if (!this.timer) {
        this.offsetwidth = val;
        // this.showmenu = this.offsetwidth > 700;
        // this.changeheight(val);
        this.timer = true;
        let that = this;
        let timeout = setTimeout(function () {
          // clearTimeout(timeout);/
          //频繁触发 resize 函数，会导致页面很卡
          that.timer = false;
        }, 100);
      }
    },
  },
  mounted() {
    window.sessionStorage.getItem("activeIndex")
      ? (this.activeIndex = window.sessionStorage.getItem("activeIndex"))
      : "";
    let that = this;
    window.addEventListener("resize", () => {
      window.offsetwidth = document.documentElement.clientWidth;
      that.offsetwidth = window.offsetwidth;
    });
    // router.beforeEach((to, from, next) => {});
  },
  methods: {
     Submit1(key) {
      this.key = key;
      var PageId = document.querySelector("#pages" + key);
      // console.log(PageId.offsetTop,PageId)
      //  top: parseInt(PageId.offsetTop - 100),
      if (key == 1) {
        window.scrollTo({
          top: 130,
          behavior: "smooth",
        });
        // this.showmenu = false;
      } else {
        window.scrollTo({
          top: PageId.offsetTop,
          behavior: "smooth",
        });
        // this.showmenu = false;
      }
    },
    Submit(key) {
      this.key = key;
      var PageId = document.querySelector("#pages" + key);
      // console.log(PageId.offsetTop,PageId)
      //  top: parseInt(PageId.offsetTop - 100),
      if (key == 1) {
        window.scrollTo({
          top: 130,
          behavior: "smooth",
        });
        // this.showmenu = false;
      } else {
        window.scrollTo({
          top: PageId.offsetTop,
          behavior: "smooth",
        });
        // this.showmenu = false;
      }
    },
    getinfo(data) {
      this.activeIndex = data;
      window.sessionStorage.setItem("activeIndex", "/AboutUs");
      this.$router.push("/AboutUs");
    },
    handleSelect(key, keyPath) {
      window.sessionStorage.setItem("activeIndex", key);
      this.activeIndex = key;
      this.showmenu = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-header {
  z-index: 10;
  ul {
    margin-left: 0 !important;
    display: contents;
  }
}
.flex_around {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 920px) {
  .el-menu {
    z-index: 99;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: #283144;
    justify-content: space-around;
  }
  .el-menu-item {
    font-size: 14px;
    text-align: left;
    color: #303133;
    padding: 0 12px;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
    box-sizing: border-box;
  }
}
.h5elmenu {
   color: rgb(34, 172, 56) !important;
  background-color: white !important;
}
@media screen and (min-width: 919px) {
  
  .el-menu {
    border-right: solid 1px #e6e6e6;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: #fff;
  }
}
.el-button {
  color: #fff;
  background-color: #545c64;
  border: 1px solid #fff;
  font-weight: 700;
}
.content {
  height: calc(100vh - 60px);
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu-item {
  background-color: white !important;
  font-size: 15px;
  font-weight: 700;
}
.el-menu--horizontal {
  background-color: white !important;
  font-size: 15px;
  font-weight: 700;
}
::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
  // background-color: white !important;
}

::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  // color: rgb(34, 172, 56) !important;
  // background-color: white !important;
  // border-bottom-color: rgb(34, 172, 56) !important;
}
::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title.is-active {
  // color: rgb(34, 172, 56) !important;
  // background-color: white !important;
  // border-bottom-color: rgb(34, 172, 56) !important;
}
.el-menu-item.is-active {
  color: rgb(34, 172, 56) !important;
  // background-color: white !important;
  border-bottom-color: rgb(34, 172, 56) !important;
}

.el-menu-item:hover {
  // color: rgb(34, 172, 56) !important;
  // background-color: white !important;
  // border-bottom-color: rgb(34, 172, 56) !important;
}

.el-menu--horizontal .el-menu {
  background: none;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  // background-color: rgb(34, 172, 56);
  // float: none;
  // height: 36px;
  // line-height: 36px;
  // padding: 0 22px;
  // color: rgb(34, 172, 56) !important;
  // z-index: 100;
  // font-weight: 500;
}

// 二级菜单 选中的样式
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  color: rgb(34, 172, 56) !important;
  // background-color: white !important;
  border-bottom-color: rgb(34, 172, 56) !important;
}
// 二级菜单 选中的样式
.el-menu--horizontal > .el-submenu:hover .el-submenu__title {
  color: rgb(34, 172, 56) !important;
  // background-color: white !important;
  border-bottom-color: rgb(34, 172, 56) !important;
}
// .el-submenu__title {
//   font-weight: 700;
//   font-size: 14px;
//   color: #303133;
//   padding: 0 20px;
//   cursor: pointer;
//   transition: border-color 0.3s, background-color 0.3s, color 0.3s;
//   box-sizing: border-box;
// }
/* 深选择器：如果相对设置了scoped的子组件里的元素进行控制可以使用'>>>'或者'deep'设置选中或悬浮的颜色*/
::v-deep .el-submenu .el-submenu__title {

      font-weight: 700;
  font-size: 14px;
  color: #303133;
    //  background-color: white !important;
  }
.wg_80 {
  width: 80%;
}
.wg_65 {
  width: 65%;
}.el-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* 确保header在其他内容之上 */
}.some-other-element {
  position: relative; /* 或absolute/fixed，根据需要 */
  z-index: 999; /* 确保这个元素在header之下 */
}
</style>
