<template>
  <div v-title :data-title="$route.name">
    <div
      v-if="offsetwidth > 850"
      style="
        width: 100%;
        overflow: hidden;
        position: relative;
        min-width: 1349px;
        margin-top: 65px;
      "
    >
      <img style="width: 100%" src="../assets/img/PC/contactUs.jpg" alt="" />
      <img
        style="width: 100%"
        src="../assets/imgthree/PCNEW/homebottom.jpg"
        alt=""
      />
    </div>
    <div
      v-else
      style="
        width: 100%;
        border: 1px solid #f8f9fa;
        overflow: hidden;
        position: relative;
        margin-top: 65px;
      "
    >
      <img style="width: 100%" src="../assets/img/H5/contactUs.jpg" alt="" />
      <img
        style="width: 100%"
        src="../assets/imgthree/H5NEW/底部H5.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  mounted() {
    // window.fbq("track", "ViewContent", {
    //   content_type: "Contact Us",
    //   content_ids: ["10005"],
    //   content_name: "Contact Us",
    //   content_category: "Contact Us",
    // });
  },
  methods: {},
};
</script>

<style></style>
